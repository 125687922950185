@import './typography';

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html {
  position: relative;
  overflow-x: hidden;
}

strong {
  color: $white;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1.75;
  font-weight: 400;

  color: $black;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;

  background: url('../../images/scratches.png');
  background-color: $background-color;
  overflow-x: hidden;
}

.step-last {
  height: 100vh;
  display: flex;
}

.airpod-right,
.airpod-left {
  position: absolute;
  width: 150px;
  z-index: 1;
}
.airpod-left {
  right: 5%;
  top: 20%;

  .step-last & {
    top: 10%;
  }
}

.airpod-right {
  left: 5%;
  top: 60%;

  .step-last & {
    top: 50%;
  }
}

.rainbow {
  position: absolute;
  width: 100%;

  .step-one & {
    top: 30%;
  }

  .step-two & {
    bottom: 40%;
  }

  .step-three & {
    bottom: 20%;
  }
}

.rainbow-right {
  background: url('../../images/rainbow2.png') no-repeat;
  background-size: cover;
  height: 420px;
  top: 0;

  @media screen and (min-width: 1920px) {
    height: 500px;
    top: -60px;
  }
}

.survey-form {
  padding-bottom: 20px;
}

main {
  position: relative;
  z-index: 2;
  color: $white;
  width: 80%;
  min-width: 300px;
  max-width: 1140px;
  margin: 25px auto;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
  z-index: 2;
}

.modal-content {
  background-color: $background-color;
  margin: 30px auto;
  padding: 35px;
  width: 80%;
  word-wrap: break-word;

  p {
    color: $white;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  li {
    line-height: 24px;
  }

  h1,
  h2 {
    text-align: center;
    color: $white;
  }

  h3 {
    color: $white;
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    font-size: 14px;
    margin-bottom: 10px;
    color: $white;
  }

  .modal-text {
    height: 65vh;
    overflow-y: scroll;
  }

  .section {
    margin-bottom: 20px;
  }
}

.modal-close {
  color: $grey;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: end;
  position: relative;
  top: -15px;

  &.button {
    background: url('../../images/btn-background.png') no-repeat center;
    background-size: contain;
    font-size: 20px;
    color: $white;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    border: none;
    outline: none;
    transition: 0.2s ease;
    width: 200px;
    height: 80px;
    margin: 30px auto 0;
  }
}

.modal-close:hover,
.modal-close:focus {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

fieldset {
  background-color: $transparant-grey;
  border-radius: 15px;
  padding: 35px 55px;
  margin: 20px 0;
  // backdrop-filter: blur(5px);

  &.terms-of-use {
    display: flex;
    align-items: center;
    background-color: unset;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 15px 75px;
    backdrop-filter: unset;
    max-width: 683px;

    .terms-of-use__container {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    span {
      cursor: pointer;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .legend-label,
  legend {
    color: $orange;
    font-weight: 600;
    font-size: 22px;
  }

  .text-input,
  textarea {
    border: 1px solid rgba(250, 250, 250, 0.8);
    border-radius: 10px;
    background-color: rgba(37, 37, 37, 0.5);
    width: 100%;
    height: 60px;
    padding-left: 25px;
    padding-right: 58px;
    line-height: 20px;
    resize: none;
    margin: 10px 0;
    color: $white;

    &::placeholder {
      font-size: 22px;
      font-weight: 400;
      font-style: italic;
    }
  }

  textarea {
    height: auto;
    padding: 10px;
    resize: vertical;
  }

  .radio-btn__wrapper {
    display: flex;
    flex-direction: column;

    .direction-row {
      flex-direction: row;
    }

    label {
      margin: 5px 10px;
      width: fit-content;
    }

    input[type='radio'] {
      display: none;
    }

    .radio-container {
      display: inline-block;
      position: relative;
      width: 22px;
      height: 22px;
      border: 1px solid $white;
      border-radius: 50%;
      cursor: pointer;
      vertical-align: middle;
    }

    .radio-container::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      background-color: $orange;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.2s ease, height 0.2s ease;
    }

    input[type='radio']:checked + .radio-container::before {
      width: 15px;
      height: 15px;
    }

    .radio-label {
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .checkbox-btn__wrapper {
    display: flex;
    flex-direction: column;

    label {
      margin: 5px 0;
      width: fit-content;
    }

    input[type='checkbox'] {
      display: none;
    }

    .checkbox-container {
      display: inline-block;
      position: relative;
      width: 22px;
      height: 22px;
      border: 1px solid $white;
      border-radius: 6px;
      cursor: pointer;
      vertical-align: middle;
    }

    .checkbox-container::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      background-color: $orange;
      border-radius: 4px;
      transform: translate(-50%, -50%);
      transition: width 0.2s ease, height 0.2s ease;
    }

    input[type='checkbox']:checked + .checkbox-container::before {
      width: 15px;
      height: 15px;
    }

    .checkbox-label {
      vertical-align: middle;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

fieldset,
textarea {
  .alerts-border {
    border: 1px solid;
    animation: blink 1s;
    animation-iteration-count: 5;
    border-radius: 15px;
  }

  @keyframes blink {
    50% {
      border-color: #ff0000;
    }
  }

  .err-msg {
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    text-align: end;
    color: #ff0000;
  }
}

.head {
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;

  .logo {
    max-width: 200px;
    width: 100%;
  }

  h1 {
    margin: 25px 0;
  }

  &_left {
    flex-basis: 62%;
  }

  &_right {
    margin-left: 30px;
    img {
      max-width: 300px;
    }
  }

  p {
    color: $grey;
    font-size: 16px;
    text-align: justify;
    font-weight: 500;
    line-height: 1.6;
  }
}

.circle-container {
  display: flex;
  align-items: center;

  h2 {
    margin-left: 20px;
  }
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: $orange;
  color: $black;
  font-size: 22px;
  border-radius: 50%;
  font-weight: 600;
}

.submit-btn {
  background: url('../../images/btn-background.png') no-repeat center;
  background-size: contain;
  font-size: 20px;
  color: $white;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  outline: none;
  transition: 0.2s ease;
  width: 200px;
  height: 80px;
  margin: 0 auto;
}

.bottom {
  &__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      color: #d7d7d7;
      font-size: 16px;
      text-align: justify;
      font-weight: 500;
      line-height: 1.6;
      max-width: 93%;

      span {
        font-weight: 800;
        color: $orange;
      }
    }
  }

  &__play-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    .play {
      max-width: 400px;
      width: 100%;
    }

    .play-links {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      img {
        max-width: 270px;
        height: 65px;

        &.apple {
          height: 63px;
        }
      }

      a {
        margin: 0 5px 20px;
      }
    }

    .listen-now {
      max-width: 350px;
      transform: translateX(-140px);
    }
  }

  &__next-step {
    display: flex;
    align-items: baseline;
    max-width: 100%;
    justify-content: space-between;

    div:not(.circle) {
      display: flex;
      align-items: baseline;
    }

    h2 {
      margin-left: 30px;
    }
  }
}

.casette {
  z-index: 3;
  width: 24%;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 1025px) {
    display: none;
  }
}

.thank-you {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto 100px;
  position: static;
  height: 100vh;

  &_content {
    background-color: rgba(20, 20, 20, 0.9);
    border-radius: 15px;
    padding: 35px 55px;
    margin: 110px 0 20px;
    backdrop-filter: blur(5px);
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &__text {
      .airpod-right {
        top: -50px;
        right: 0px;
        left: unset;
        width: 160px;
        z-index: -1;
      }

      .text-wrapper {
        max-width: 100%;
        text-align: center;
      }

      h2 {
        color: $orange;
        font-weight: 800;
        font-size: 32px;
        margin-bottom: 15px;
      }

      p {
        font-weight: 500;
        font-size: 26px;
        line-height: 1.3;

        &.follow-text,
        &.join-us-text {
          font-size: 22px;
        }
      }

      .follow-container {
        margin-bottom: 25px;

        .follow-text {
          max-width: 790px;
          margin: 0 auto 1.5rem;
        }

        .join-us-text {
          max-width: 890px;
          margin: 0 auto 1.5rem;
        }

        img {
          margin: 0 5px;
        }
      }
    }

    &__surveys {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 850px;
      margin: 15px auto 0px;

      .survey-container {
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        min-height: 475px;
        position: relative;

        img {
          width: 100%;
          margin-bottom: 15px;
        }

        p {
          font-size: 16px;
          text-align: center;
        }

        button {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  .logo {
    max-width: 200px;
    width: 100%;
    margin-right: auto;
  }

  h1 {
    text-align: center;
    font-size: 46px;
    line-height: 1;
    font-weight: 900;
    max-width: 450px;

    text-transform: uppercase;
    font-style: italic;

    text-shadow: -4px -4px #221f20, 0px -4px #221f20, 4px -4px #221f20, -5px 0px #221f20, 1px 0px #221f20,
      5px 0px #221f20, -4px 4px #221f20, 0px 4px #221f20, 4px 4px #221f20, -2px -2px #221f20, 2px -2px #221f20,
      6px -2px #221f20, -2px 6px #221f20, 2px 6px #221f20, 6px 6px #221f20, 0px 0px #221f20, 4px 0px #221f20,
      8px 0px #221f20, 0px 8px #221f20, 4px 8px #221f20, 8px 8px #221f20, 2px 2px #221f20, 6px 2px #221f20,
      10px 2px #221f20, 2px 10px #221f20, 6px 10px #221f20, 10px 10px #221f20, 4px 4px #221f20,
      8px 4px #221f20, 12px 4px #221f20, 4px 12px #221f20, 8px 12px #221f20, 12px 12px #221f20,
      6px 6px #221f20, 10px 6px #221f20, 14px 6px #221f20, 6px 14px #221f20, 10px 14px #221f20,
      14px 14px #221f20, 8px 8px #221f20, 12px 8px #221f20, 16px 8px #221f20, 8px 16px #221f20,
      12px 16px #221f20, 16px 16px #221f20, 10px 10px #221f20, 14px 10px #221f20, 18px 10px #221f20,
      10px 18px #221f20, 14px 18px #221f20, 18px 18px #221f20, 12px 12px #221f20, 16px 12px #221f20,
      20px 12px #221f20, 12px 20px #221f20, 16px 20px #221f20, 20px 20px #221f20, 14px 14px #221f20,
      18px 14px #221f20, 22px 14px #221f20, 14px 22px #221f20, 18px 22px #221f20, 22px 22px #221f20,
      16px 16px #221f20, 20px 16px #221f20, 24px 16px #221f20, 16px 24px #221f20, 20px 24px #221f20,
      24px 24px #221f20, 18px 18px #221f20, 22px 18px #221f20, 26px 18px #221f20, 18px 24px #221f20,
      22px 24px #221f20, 26px 24px #221f20, 20px 20px #221f20, 24px 20px #221f20, 28px 20px #221f20,
      20px 26px #221f20, 24px 26px #221f20, 28px 26px #221f20, 22px 22px #221f20, 26px 22px #221f20,
      30px 22px #221f20, 22px 28px #221f20, 26px 28px #221f20, 30px 28px #221f20;

    padding-bottom: 30px;
    padding-top: 30px;
    transform: rotate(-5deg);
  }

  footer {
    position: absolute;
    bottom: 0;

    p {
      font-size: 16px;
      font-weight: 400;
      padding: 5px 0 20px;
      text-align: center;
    }
  }

  &.sorry {
    justify-content: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(-160px);
    }
    fieldset {
      text-align: center;
    }

    p {
      font-size: 18px;

      .try-again {
        color: #0d6efd;
        cursor: pointer;

        &:hover {
          color: #0a58ca;
        }
      }
    }
  }

  @media screen and (max-width: 1360px) {
    .logo {
      margin: 0 auto 30px;
    }

    h1 {
      font-size: 34px;
      transform: rotate(-7deg);
    }

    &_content {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      padding: 0 20px;

      &__text {
        position: relative;
        padding-bottom: 30px;

        .airpod-right {
          display: none;
        }

        .text-wrapper {
          max-width: unset;
          width: 100%;
          text-align: center;
        }

        h2 {
          font-size: 18px;
          margin: 30px 0;
        }

        p {
          font-size: 20px;

          &.follow-text,
          &.join-us-text {
            font-size: 16px;
          }
        }

        .follow-container {
          img {
            margin: 5px 5px 0;
            height: 60px;
          }

          .follow-text {
            max-width: 610px;
          }

          .join-us-text {
            max-width: 650px;
          }
        }
      }

      &__surveys {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 35px;

        .survey-container {
          width: 220px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 20px;

          img {
            width: 100%;
            margin-bottom: 15px;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &_content {
      &__text {
        .follow-container {
          img {
            margin: 5px 5px 0;
            height: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .rainbow {
    .step-last & {
      bottom: 39%;
    }
  }

  .rainbow-right {
    background: url('../../images/rainbow2.png') no-repeat;
    background-size: cover;
    height: 440px;
    top: -40px;
  }

  .airpod-left,
  .airpod-right {
    display: none;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  main {
    width: 85%;
  }

  legend {
    line-height: 1.3;
  }

  .head {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 15px;
    }
  }

  .bottom {
    div:not(.circle) {
      align-items: normal;
      margin-bottom: 15px;
    }

    &__text {
      p {
        max-width: 83%;
      }
    }

    &__next-step {
      flex-direction: column;
      h2 {
        max-width: 73%;
        margin-left: 20px;
        font-size: 22px;
      }
    }

    &__play-container {
      .listen-now {
        max-width: 200px;
        margin: 0 auto;
        margin-bottom: 20px;
        transform: translateX(-80px);
      }
    }
  }

  .circle-container {
    h2 {
      max-width: 73%;
      font-size: 26px;
    }
  }

  fieldset {
    padding: 20px 15px;

    &.terms-of-use {
      padding: 15px 25px;
    }

    legend,
    .legend-label {
      font-size: 18px;
      line-height: 1.3;
    }
  }
}
