// Change breakpoints of Bootstrap
$grid-gutter-width: 32px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1480px
);

$container-max-widths: (
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1480px
);

$spacer: 2rem !default;
$gutters: (
  0: 0,
  1: $spacer * 0.5, // 16px
  2: $spacer * 1, // 32px
  3: $spacer * 1.75, // 56px
  4: $spacer * 3, // 96px
  5: $spacer * 4, // 128px
  6: $spacer * 4.875, // 156px
);

// Import Bootstrap styles
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');