h1, h2, h3, h4, h5 {
  font-family: "Montserrat", sans-serif;
  color: $heading-color;
}

h1 {
  font-size: 24px;
  line-height: 1.20;
  font-weight: 600;
  text-transform: none;

  margin-bottom: $spacer;

  &:last-child {
    margin-bottom: 0;
  }
}

h2 {
  font-size: 18px;
  line-height: 1.20;
  font-weight: 600;

  margin-bottom: $spacer;

  &:last-child {
    margin-bottom: 0;
  }


  &.custom-underline {
    position: relative;
    display: inline-block;

    &::before {
      height: 8px;
    }
  }
}

h3 {
  font-size: 48px;
  line-height: 1.20;
  font-weight: 600;

  margin-bottom: $spacer * 0.75;

  &:last-child {
    margin-bottom: 0;
  }
}

h4 {
  font-size: 36px;
  line-height: 1.20;
  font-weight: 600;

  margin-bottom: $spacer * 0.75;

  &:last-child {
    margin-bottom: 0;
  }
}

h5 {
  font-size: 30px;
  line-height: 1.20;
  font-weight: 600;

  margin-bottom: $spacer * 0.5;

  &:last-child {
    margin-bottom: 0;
  }
}

p {
  font-size: 24px;
  line-height: 1.75;
  font-weight: 700;
  color: $white;

  margin-bottom: $spacer * 0.75;

  &:last-child {
    margin-bottom: 0;
  }
}

strong, b {
  font-weight: 600;
  color: $black
}

a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 16px;

    &.text-sub-heading {
      font-size: 18px;
    }
  }
}